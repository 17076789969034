// Main Menu
document.addEventListener('DOMContentLoaded', (event) => {
    const body = document.querySelector('body');
    const siteNavigation = document.querySelector('.siteNavigation');

    if (siteNavigation) {

        const menuToggle = siteNavigation.querySelector('.menuToggle');
        const menuContainer = siteNavigation.querySelector('.menuContainer');
        const siteOverlay = document.querySelector('.site-overlay');

        // Toggle menu
        menuToggle.addEventListener('click', () => {

            if (menuContainer.classList.contains('expanded')) {
                closeMeny();
            } else {
                openMeny();
            }
        });

        siteOverlay.addEventListener('click', () => {
            if (menuContainer.classList.contains('expanded')) {
                closeMeny();
            }
        });

        document.addEventListener('keydown', function(event) {
            if (event.key == "Escape") {
                if (menuContainer.classList.contains('expanded')) {
                    closeMeny();
                }
            }
        });

        const menu = menuContainer.querySelector('.menu');
        const links = menu.querySelectorAll('a[href]');
        const lastLink = links[links.length - 1];


        lastLink.addEventListener('keydown', function(event) {
            if (event.key == "Tab" && !event.shiftKey) {
                event.preventDefault();
                closeMeny();
                menuToggle.focus();
            }
        });


        function openMeny() {

            let screenReaderText = menuToggle.querySelector('.screen-reader-text');
            let menuText = menuToggle.querySelector('.menu-text');

            menuToggle.setAttribute('aria-expanded', 'true');
            menuContainer.classList.remove('hidden');
            menuContainer.classList.add('expanded');
            menuContainer.setAttribute('aria-hidden', 'false');
            body.classList.add('menuOpen');
            siteOverlay.classList.add('menuVisible');

            screenReaderText.innerHTML = 'Stäng rikshem huvudmeny';
            menuText.innerHTML = 'Stäng';
        }

        function closeMeny() {

            let screenReaderText = menuToggle.querySelector('.screen-reader-text');
            let menuText = menuToggle.querySelector('.menu-text');

            menuToggle.setAttribute('aria-expanded', 'false');
            menuContainer.classList.add('hidden');
            menuContainer.classList.remove('expanded');
            menuContainer.setAttribute('aria-hidden', 'true');
            body.classList.remove('menuOpen');
            siteOverlay.classList.remove('menuVisible');

            screenReaderText.innerHTML = 'Öppna rikshem huvudmeny';
            menuText.innerHTML = 'Meny';
        }

        // Open close the submenu panels:
        const subMenuToggles = menuContainer.querySelectorAll('.submenu-toggle');

        subMenuToggles.forEach((toggle) => {
            if (toggle.getAttribute("aria-expanded") == "true") {
                const subMenu = toggle.nextElementSibling;
                subMenu.classList.remove('collapsed');
                subMenu.classList.add('expanded');
            } 

            toggle.addEventListener('click', (e) => {
                e.preventDefault();
                const subMenu = toggle.nextElementSibling;

                if (subMenu.classList.contains('expanded')) {
                    closeSubMenu(subMenu);
                } else {
                    openSubMenu(subMenu);
                }
            });

            function openSubMenu(subMenu) {
                const toggle = subMenu.previousElementSibling;
                toggle.setAttribute('aria-expanded', 'true');
                subMenu.classList.remove('collapsed');
                subMenu.classList.add('expanding');

                setTimeout(() => {
                    subMenu.classList.remove('expanding');
                    subMenu.classList.add('expanded');
                }, 500);
            }

            function closeSubMenu(subMenu) {
                const toggle = subMenu.previousElementSibling;
                toggle.setAttribute('aria-expanded', 'false');
                subMenu.classList.add('collapsing');

                setTimeout(() => {
                    subMenu.classList.remove('collapsing');
                    subMenu.classList.remove('expanded');
                    subMenu.classList.add('collapsed');
                }, 500);
            }
        });
    }
});
