import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";

const initFancybox = () => {
    Fancybox.bind("[data-fancybox]", {
        // Your custom options
    });
};

export {initFancybox};
