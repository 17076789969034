import generateRandomString from './utillity';

const dropdownAccordions = document.querySelectorAll('.accordion');

if (dropdownAccordions) {
  const accordionArray = Array.from(dropdownAccordions);

  accordionArray.forEach((accordion) => {
    
    const accordionTriggerTitles = accordion.querySelectorAll('.accordion-title');
    const accordionTriggerTitlesArray = Array.from(accordionTriggerTitles);

    accordionTriggerTitlesArray.forEach((accordionTriggerTitle) => {
      const accordionToggle = accordionTriggerTitle.querySelector('button');
      const accordionToggleText = accordionTriggerTitle.querySelector('span');
      const accordionPanel = accordionTriggerTitle.nextElementSibling;
      accordionPanel.style.opacity = 1;

      const accordionToggleIcon = accordionToggle.querySelector('svg');
      if (accordionToggleIcon) {
        accordionToggleIcon.setAttribute('aria-hidden', 'true');
      }

      // Generate random ID for each accordions Title text & Panel and enhance the accessibility of the accordion progressively
      const accordionToggleTextID = `accordionToggleText-${generateRandomString(5, { includeUppercase: true })}`;
      accordionToggleText.setAttribute('id', accordionToggleTextID);

      const accordionPanelID = `accordionPanel-${generateRandomString(5, { includeUppercase: true })}`;
      accordionPanel.setAttribute('id', accordionPanelID);

      // Set the accordion toggle aria-controls attribute to the ID of the accordion panel and set the button to role button.
      accordionToggle.setAttribute('aria-controls', accordionPanelID);
      accordionToggle.setAttribute('aria-expanded', 'false');
      accordionToggle.setAttribute('type', 'button');

      // Set the accordion panel aria-labelledby attribute to the ID of the accordion toggle text and set the panel to role region.
      accordionPanel.setAttribute('aria-labelledby', accordionToggleTextID);
      accordionPanel.setAttribute('aria-hidden', 'true');
      accordionPanel.setAttribute('role', 'region');
      accordionPanel.classList.add('collapsed');

      accordionToggle.addEventListener('click', () => {

        // If the accordion is not expanded, expand it
        if (accordionToggle.getAttribute('aria-expanded') === 'false') {
          openPanel();
        } else {
          closePanel();
        }

        // function for opening the the panel:
        function openPanel() {
          accordionToggle.setAttribute('aria-expanded', 'true');
          accordionPanel.setAttribute('aria-hidden', 'false');
          accordionPanel.classList.remove('collapsed');
          accordionPanel.classList.add('expanded');
        }

        // function for closing the the panel:
        function closePanel() {

          accordionToggle.setAttribute('aria-expanded', 'false');
          accordionPanel.classList.add('animating');

          // Give the animation time to run before removing the expanded class:
          setTimeout(() => {

            accordionPanel.classList.remove('animating');
            accordionPanel.classList.remove('expanded');
            accordionPanel.classList.add('collapsed');
            accordionPanel.setAttribute('aria-hidden', 'true');
        
          }, 400); // 400ms is the duration of the animation + delay
        }
      });
    });
  });


  // Automatically open accordion panel from anchor link in URL
  if (window.location.hash) {
    const anchorId = window.location.hash.substring(1); // Get hash and remove #
    const anchorElement = document.getElementById(anchorId);

    if (anchorElement) {
      const accordionTriggerTitle = anchorElement.nextElementSibling;
      const accordionToggle = accordionTriggerTitle.querySelector('button');
      const accordionPanel = accordionTriggerTitle.nextElementSibling;
  
      // Open panel
      if (accordionToggle) {
        accordionToggle.setAttribute('aria-expanded', 'true');
        accordionPanel.setAttribute('aria-hidden', 'false');
        accordionPanel.classList.remove('collapsed');
        accordionPanel.classList.add('expanded');
      }
    }
  }
}