// Main Menu
document.addEventListener('DOMContentLoaded', (event) => {
    const articleSidebar = document.querySelector('.articleSidebar');

    if (articleSidebar) {

        const menuContainer = articleSidebar.querySelector('.menuContainer');


        // Open close the submenu panels:
        const subMenuToggles = menuContainer.querySelectorAll('.submenu-toggle');

        subMenuToggles.forEach((toggle) => {
            if (toggle.getAttribute("aria-expanded") == "true") {
                const subMenu = toggle.nextElementSibling;
                subMenu.classList.remove('collapsed');
                subMenu.classList.add('expanded');
            } 

            toggle.addEventListener('click', (e) => {
                e.preventDefault();
                const subMenu = toggle.nextElementSibling;

                if (subMenu.classList.contains('expanded')) {
                    closeSubMenu(subMenu);
                } else {
                    openSubMenu(subMenu);
                }
            });

            function openSubMenu(subMenu) {
                const toggle = subMenu.previousElementSibling;
                toggle.setAttribute('aria-expanded', 'true');
                subMenu.classList.remove('collapsed');
                subMenu.classList.add('expanding');

                setTimeout(() => {
                    subMenu.classList.remove('expanding');
                    subMenu.classList.add('expanded');
                }, 500);
            }

            function closeSubMenu(subMenu) {
                const toggle = subMenu.previousElementSibling;
                toggle.setAttribute('aria-expanded', 'false');
                subMenu.classList.add('collapsing');

                setTimeout(() => {
                    subMenu.classList.remove('collapsing');
                    subMenu.classList.remove('expanded');
                    subMenu.classList.add('collapsed');
                }, 500);
            }
        });
    }  
})
