// Generarte a random string of the specified length
// Use like: generateRandomString(10, { includeUppercase: true, includeSymbols: true });

export default function generateRandomString(length, options = {}) {

    // Define the default set of characters to be used
    const defaultCharacters = 'abcdefghijklmnopqrstuvwxyz0123456789';
  
    // Override the default options with the user-specified options
    let {
      characters = defaultCharacters,
      includeUppercase = false,
      includeSymbols = false,
    } = options;
  
    // Add uppercase letters and symbols if requested
    if (includeUppercase) {
      characters += 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    }
    if (includeSymbols) {
      characters += '!@#$%^&*()_-+={}[]\\|;:\'",.<>/?';
    }
  
    // Check that the characters string is not empty or null
    if (!characters) {
      throw new Error('Characters string cannot be empty or null');
    }
  
    // Define the variable to store the random string
    let randomString = '';
  
    // Generate a random string of the specified length
    const randomValues = new Uint8Array(length);
    crypto.getRandomValues(randomValues);
    for (let i = 0; i < length; i++) {
      const index = randomValues[i] % characters.length;
      randomString += characters.charAt(index);
    }
  
    return randomString;
}
