import Swiper from 'swiper/bundle';
// import Swiper styles
import 'swiper/css/bundle';

const renderBullet = (index,className) => {
    return "<div class=\""+className+"\"><div class=\"bullet\" tabindex=\"0\" role=\"button\" aria-label=\"Gå till bild "+(index+1)+"\" aria-current=\"true\"></div></div>";
};
    
const initSwiper = () => {
    var swiper = new Swiper(".swiper", {
        slidesPerView: 1.4,
        spaceBetween: 14,
        centeredSlides: false,
        loop: true,
        pagination: {
            el: ".swiper-pagination",
            clickable: true,
            renderBullet: renderBullet
        },
        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
        },
        breakpoints: {
            700: {
                centeredSlides: true,
                slidesPerView: 2,
                spaceBetween: 24,
            }
        }
    });
};

export {initSwiper};
