if (!("onscrollend" in window)) {
    const s = new Event("scrollend"), i = new Set;
    document.addEventListener("touchstart", e => {
        for (let t of e.changedTouches) i.add(t.identifier)
    }, {passive: !0}), document.addEventListener("touchend", e => {
        for (let t of e.changedTouches) i.delete(t.identifier)
    }, {passive: !0});
    let l = new WeakMap;

    function e1(e, t, n) {
        let o = e[t];
        e[t] = function () {
            let e = Array.prototype.slice.apply(arguments, [0]);
            o.apply(this, e), e.unshift(o), n.apply(this, e)
        }
    }

    function t1(e, t, n, o) {
        if ("scroll" != t && "scrollend" != t) return;
        let r = this, d = l.get(r);
        if (void 0 === d) {
            let t = 0;
            d = {
                scrollListener: e => {
                    clearTimeout(t), t = setTimeout(() => {
                        i.size ? setTimeout(d.scrollListener, 100) : (r.dispatchEvent(s), t = 0)
                    }, 100)
                }, listeners: 0
            }, e.apply(r, ["scroll", d.scrollListener]), l.set(r, d)
        }
        d.listeners++
    }

    function n1(e, t, n) {
        if ("scroll" != t && "scrollend" != t) return;
        let o = this, s = l.get(o);
        void 0 !== s && (s[t]--, --s.listeners > 0 || (e.apply(o, ["scroll", s.scrollListener]), l.delete(o)))
    }

    e1(Element.prototype, "addEventListener", t1), e1(window, "addEventListener", t1), e1(document, "addEventListener", t1), e1(Element.prototype, "removeEventListener", n1), e1(window, "removeEventListener", n1), e1(document, "removeEventListener", n1)
}
var o = {__proto__: null};
export {o as scrollend};
