
import './js/scrollyfill';

import './js/utillity.js';

import './js/carouselSlider.js'

import './js/dropdownAccordion.js'

import './js/mainNavigation'

import './js/sidebarNavigation'

import './js/siteTitle'

import './js/script';

import './css/style.css';

import './js/googleMaps';

import { initSwiper } from "./js/swiper.js";

initSwiper();

import { initFancybox } from "./js/fancybox.js";

initFancybox();
