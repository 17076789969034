const heroTitle = document.querySelector('.heroTitle');

if(heroTitle) {
    const avalibleAppartments = heroTitle.querySelector('.avalibleAppartments');
    const numberOfAvalibleAppartments = avalibleAppartments.querySelector('.titleStatic').textContent;
    const initialApparments = 0;

    const titleCounter = avalibleAppartments.querySelector('.titleCounter');

    // Make the correct room for our numbers counter inside the title to avoid layout shift.
    const characters = Math.floor(Math.log10(numberOfAvalibleAppartments) + 1);
    titleCounter.classList.add('cells-' + characters );

    let counter = initialApparments;
    const interval = numberOfAvalibleAppartments / 20;
    const timeout = 1000 / 20;
    const timer = setInterval(() => {
        counter += interval;
        titleCounter.textContent = Math.floor(counter);
        if (counter >= numberOfAvalibleAppartments) {
            clearInterval(timer);
            titleCounter.textContent = numberOfAvalibleAppartments;
        }
    }, timeout);
}
