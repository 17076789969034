

async function initialize() {
    if(typeof google === 'undefined') {
        return;
    }
    
    const { Map } = await google.maps.importLibrary("maps");
    const {PlacesService} = await google.maps.importLibrary("places");
    
    //Kartinställningar
    var categories = {
        "shopping": ["clothing_store", "convenience_store", "department_store", "shoe_store",
            "electronics_store", "book_store", "furniture_store",
            "home_goods_store", "store", "shopping_mall"
        ],
        "bus_station": ["bus_station", "train_station", "subway_station", "transit_station"],
        "school": ["university", "school"],
        "health": ["dentist", "doctor", "veterinary_care", "physiotherapist", "hospital"],
        "culture": ["art_gallery", "gym", "stadium", "park", "museum", "movie_theater", "library"]
    };
    var markerArr = Array();
    var gmarkers = Array();

    var map = document.getElementById("map_canvas");
    var address;
    if(map) {
        address = map.getAttribute("data-address")
    }
    
    var latlng;
    
    if (address != undefined) {
        var geocoder = new google.maps.Geocoder();

        //Skapar informationsfönster
        var infowindow = new google.maps.InfoWindow({});

        //Skapar karta
        geocoder.geocode({
            'address': address
        }, function (results, status) {
            if (status == 'OK') {
                latlng = results[0].geometry.location;

                var project = latlng;

                var map = new Map(document.getElementById('map_canvas'), {
                    center: project,
                    zoom: 13,
                    mapId: "rikshem",
                });

                //Skapar markör
                var createMarker = function (cords, icon, titleText, selected, category, textContent) {
                    //Skapar ikon pga IE11 visar inte ikonen annars
                    var image = {
                        url: icon,
                        scaledSize: new google.maps.Size(40, 40),
                    };

                    var marker = new google.maps.Marker({
                        position: cords,
                        map: map,
                        icon: image,
                        animation: google.maps.Animation.DROP,
                        title: titleText,
                        optimized: false,
                        category: category,
                    });

                    if (category != "home") {
                        gmarkers.push(marker);
                        marker.setVisible(true);
                    }

                    if (selected == 0) {
                        //Event för klick av markör
                        google.maps.event.addListener(marker, 'click', function () {
                            infowindow.setContent(textContent);
                            infowindow.open(map, marker);
                        });
                    } else {
                        //Event för att visa vald markör/objekt från start
                        showMarkerObject = function () {
                            infowindow.setContent(textContent);
                            infowindow.open(map, marker);
                        };
                        google.maps.event.addListener(marker, 'click', showMarkerObject);
                    }
                };

                //Gör en request till Google places för att hitta närliggande platser i kategorien
                var nearbyPlacesSearch = function (request, currentCategory) {
                    var service = new PlacesService(map);
                    service.nearbySearch(request, function (results, status) {
                        if (status == google.maps.places.PlacesServiceStatus
                            .OK) {
                            for (var i = 0; i < results.length; i++) {
                                var place = results[i];
                                createMarker(place.geometry.location,
                                    '/icons/' + currentCategory + '-ico.svg',
                                    place.name, "",
                                    currentCategory,
                                    "<div style='text-align:left;'><strong>" +
                                    place.name +
                                    "</strong><br />" + place.vicinity + "</div>"
                                );
                            }
                        }
                    });
                };
                //
                createMarker(project, "/icons/ico.svg", "",
                    "", "home", "Rikshem");

                //Visar valt objekt/markör från start
                if (typeof showMarkerObject == "function") {
                    showMarkerObject();
                }

                //Hanterar markörer - tilldelar funktion till checkboxarna för vilka katgorier som ska visas
                Array.prototype.forEach.call(document.getElementsByClassName('map-toggleCheckbox'), function(el, index, array){
                    el.addEventListener('click',function () {
                        Array.prototype.forEach.call(document.getElementsByClassName('map-toggleCheckbox'), function(cb,index,array) {
                            cb.checked = false;
                        });
                        
                        el.checked = true;
                        
                        var currentCategory = el.value;
                        infowindow.close();

                        if (!markerArr.includes(currentCategory)) {
                            var step;
                            for (step = 0; step < categories[currentCategory].length; step++) {

                                var request = {
                                    location: project,
                                    radius: '1000',
                                    type: categories[currentCategory][step]
                                };

                                nearbyPlacesSearch(request, currentCategory);
                            }
                            markerArr.push(currentCategory);
                        }

                        for (var i = 0; i < gmarkers.length; i++) {
                            if (gmarkers[i].category === currentCategory && el.checked) {
                                gmarkers[i].setVisible(true);
                            } else {
                                gmarkers[i].setVisible(false);
                            }
                        }
                    });
                });
            }
        });


    }
}
document.addEventListener('DOMContentLoaded', async (event) => {
    await initialize();
});

